@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.input_desc_height{
  height: 365px !important;
}

/* scrollbar designing */
::-webkit-scrollbar {
  width: .4em;
  height: .5em;
}

::-webkit-scrollbar-track {
  background-color: #201e1938;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1691A5;
  border-radius: 10px;
}

/* Modal Styling */
.ant-modal-root .ant-modal-mask {
  background: linear-gradient(to right, #1691A5, #1E284C);
  opacity: 0.8;
}

/*  */
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 100%;
  height: 130px;
  border: 2px dashed #1691A5;
  background: #1691A51A;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select:hover {
  border: 2px dashed #1691A5 !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
  width: 100%;
  height: 130px;
  border: 2px dashed #1691A5;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border: none;
}

.ant-tooltip .ant-tooltip-inner {
  display: none;
}

.anticon-eye svg {
  display: none;
}

/* custom antd radio style */
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border: 2px solid #1691A5;
  background: #1691A5;
}

.ant-radio-wrapper .ant-radio-inner {
  border: 2px solid #1691A5;
}

/* common styling classes */
.ck.ck-editor__main>.ck-editor__editable {
  height: 150px;
}
.commom_color {
  color: #1691A5 !important;
}

.very_small_font {
  font: 600 0.8rem 'Poppins', sans-serif;
}

.big_font {
  font: bold 1.4rem 'Poppins', sans-serif;
  color: #3D3D3D;
}

.medium_font {
  font: bold 1.1rem 'Poppins', sans-serif;
  color: #3D3D3D;
}

.del_btn {
  border: none;
  background: #FF5353;
  color: #fff;
  padding: 0 2rem;
  height: 30px;
}

.common_btn_back {
  background: #1691A5;
}

.common_btn {
  outline: none;
  background: #1691A5;
  height: 50px;
  color: #fff;
  border: none;
  border-radius: 10px;
  font: 500 0.8rem 'Poppins', 'sans-serif';
}

.extra_height {
  height: 35px;
}

/* Auth Styling */
.main_login_div {
  background: linear-gradient(to left, #1691A5, #1E284C), url('./assets/mask.png') center, no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 3rem;
}

.login_input_div {
  background: #ffffffd7;
  padding: 2.5rem 7rem;
  border-radius: 20px;
}

.input_feilds {
  box-shadow: 0px 3px 5px #0000800D;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  border: 1px solid #1691A54D !important;
  outline: none;
  padding: 0.2rem 1rem;
}

.login_btn_div {
  width: 80%;
  margin: auto;
}

/* dashboard list styling */
.layout_main {
  height: 100vh;
}

.sider_main {
  background: #fff !important;
  box-shadow: 5px 0px 15px #1691A51A;
}

.dropdown-toggle::after {
  color: #1691A5 !important;
}

.dropdown-menu {
  min-width: 100% !important;
  padding: 0% !important;
  border: none !important;
  background: #DFFBFF !important;
}

.dropdown-item:hover {
  background: #DFFBFF !important;
  border-radius: 8px;
}

.ant-menu-inline .ant-menu-item {
  background: #1692a521 !important;
}

.ant-menu-inline .ant-menu-item:hover {
  background: #1692a554 !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: #1691A5 !important;
  background: #1692a554 !important;
}

.ant-menu-title-content {
  font: 600 0.8rem Poppins, sans-serif !important;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background: #DFFBFF !important;
  border-bottom: none !important;
  color: #1691A5 !important;
}

.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: none !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  background: #fff !important;
  /* box-shadow: 0px 5px 25px #52575D1A; */
}

.ant-table-wrapper {
  box-shadow: 0px 5px 25px #52575D1A;
  background: #fff !important;
}

.ant-switch-checked .ant-switch-inner {
  background: #1691A5 !important;
}

.ant-pagination .ant-pagination-item-active a {
  background: #1691A5;
  color: #fff !important;
  border-radius: 50% !important;
}

.ant-pagination .ant-pagination-item-active {
  background: transparent !important;
  border: none !important;
}

.ant-drawer-left>.ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer .ant-drawer-body {
  padding: 0 !important;
}

.ant-upload-list-item-name {
  color: #1691A5 !important;
}

.del_btn:hover {
  color: #fff !important;
}

/* Notification styling */
.common_card {
  background: #fff;
  box-shadow: 0px 5px 10px #0000001A;
  border-radius: 10px;
  padding: 1rem;
  width: 50%;
}

/* profile styling */
.profile_div {
  width: 50%;
  margin: auto;
}

.content_margin {
  margin: 18px 40px;
}

.image_width {
  width: 80%;
}

.ant-table-wrapper .ant-table {
  /* width: 1300px; */
}

.personal_width{
  width: 1300px !important;
}

/* Landing page styling */
.input_fields{
  height: 50px;
  width: 100%;
  border: 1px solid #1691A526;
  padding: 0.5rem;
  background: #1691A505;
  outline: none;
  box-shadow: 5px 0px 15px #1691A51A;
  border-radius: 5px;
}

/* contact page  */
/* .ant-modal .ant-modal-content {
  width: 75%;
  margin: auto;
} */

/*privacy Page  */
.ck.ck-editor__main>.ck-editor__editable {
  height: 100%;
}

/* welcome page */
.welcome_screen{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffd7;
}

@media screen and (min-width:1600px) {
  .ant-menu-inline .ant-menu-item {
    height: 50px;
  }

  .extra_height {
    height: 40px !important;
  }

  .image_width {
    width: 80%;
  }

  .login_input_div {
    padding: 5rem 10rem;
  }

  .input_feilds {
    height: 60px;
    font-size: 1.3rem;
  }

  .common_btn {
    height: 60px;
  }

  .del_btn {
    height: 40px;
  }

  .very_small_font {
    font: 600 1.2rem 'Poppins', sans-serif;
  }

  .big_font {
    font: bold 1.8rem 'Poppins', sans-serif;
  }
}

@media screen and (max-width:1200px) {
  .ant-table-wrapper .ant-table {
    /* width: max-content; */
  }
}

@media screen and (max-width:993px) {
  .sider_main {
    display: none;
  }

  .profile_div {
    width: 70%;
  }

  .common_card {
    width: 70%;
  }

  .image_width {
    width: 50%;
  }
}

@media screen and (max-width:600px) {
  .login_input_div {
    padding: 2rem 1rem;
    border-radius: 10px;
  }

  .main_login_div {
    padding: 1.5rem;
  }

  .profile_div {
    width: 100%;
  }

  .common_card {
    width: 100%;
  }

  .content_margin {
    margin: 18px 15px;
  }
  .ant-modal .ant-modal-content {
    width: 100%;
  }
}